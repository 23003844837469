<template>
  <section class="conOfVideosGallery h-100 mb-5">
    <v-container>
      <v-row class="mt-3">
        <v-col cols="12">
          <Breadcrumb :BreadcrumbList="BreadcrumbList" />
          <HeaderPage :headerPage="{ part1: 'CNE', part2: 'Activities' }" />
        </v-col>
      </v-row>
    </v-container>
    <section class="mt-3" v-if="mediaCenter && mediaCenter.length > 0">
      <v-container>
        <v-row>
          <v-col
            lg="3"
            md="4"
            sm="6"
            cols="12"
            v-for="(media, index) in mediaCenter"
            :key="index"
          >
            <div class="conOfVideo">
              <v-card class="mx-auto" max-width="374" tile elevation="1">
                <v-img
                  height="250"
                  :src="media.image"
                  alt="item of the Image"
                ></v-img>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <div class="loaderContainer" v-else-if="isLoading">
      <v-progress-circular
        :size="120"
        :width="4"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <EmptyState v-else></EmptyState>
  </section>
</template>

<script>
import EmptyState from "@/modules/shared/components/emptystate";
import HeaderPage from "@/modules/shared/components/header-pages";
import Breadcrumb from "@/modules/shared/components/breadcrumbs-pages";
import image1 from "../../../../assets/img/cne/1.jpg";
import image2 from "../../../../assets/img/cne/15-9.jpg";
import image3 from "../../../../assets/img/cne/17-9.jpg";
import image4 from "../../../../assets/img/cne/2.jpg";
import image5 from "../../../../assets/img/cne/20-9.jpg";
import image6 from "../../../../assets/img/cne/22-6.jpg";
import image7 from "../../../../assets/img/cne/25-9.jpg";
import image8 from "../../../../assets/img/cne/26-6.jpg";
import image9 from "../../../../assets/img/cne/28-9.jpg";
import image10 from "../../../../assets/img/cne/ASIA.jpg";
import image11 from "../../../../assets/img/cne/CLL.png";
import image12 from "../../../../assets/img/cne/CML.jpg";
// import image13 from "../../../../assets/img/cne/ctcl.JPG";
import image14 from "../../../../assets/img/cne/HES.jpg";
import image15 from "../../../../assets/img/cne/ITP.jpg";

export default {
  data: () => ({
    dialog: false,
    name: "",
    menu: false,
    items: [
      {
        name: "newest",
        value: 1,
      },
      {
        name: "oldest",
        value: 2,
      },
    ],
    search: {
      terms: "",
      orderBy: null,
    },
    updatedFilter: null,
    pagination: {
      current_page: 1,
    },
    BreadcrumbList: [
      {
        text: "Resources",
        disabled: true,
      },
      {
        text: "CNE Activities",
        disabled: false,
      },
    ],
    updatedCurrent_page: null,
    mediaCenter: [
      {
        image: image1,
      },
      {
        image: image2,
      },
      {
        image: image3,
      },
      {
        image: image4,
      },
      {
        image: image5,
      },
      {
        image: image6,
      },
      {
        image: image7,
      },
      {
        image: image8,
      },
      {
        image: image9,
      },
      {
        image: image10,
      },
      {
        image: image11,
      },
      {
        image: image12,
      },
      // {
      //   image: image13,
      // },
      {
        image: image14,
      },
      {
        image: image15,
      },
    ],
    isLoading: false,
    isLoadingSearch: false,
    videoData: {
      id: null,
      title: null,
    },
  }),
  components: {
    EmptyState,
    HeaderPage,
    Breadcrumb,
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "./_videos-gallery.scss";
</style>
